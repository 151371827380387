import router from './index'
import { constantRoutes } from './index'
export const getAsyncRoutes = (arr,str = []) => {
  arr.forEach(item => {
    
    // <--       上线注释掉        -->
    item.name = '/' + item.component
    item.title = item.meta.title
    // <--       上线注释掉        -->
    
    const route = {
      path: item.path,
      name: item.title,
      // 根据后台返回的 component 的路径，引入路由对应的组件
      component:
        // () => import(`@/views/${item.component}`)
        (resolve) => require([`@/views/${item.component}.vue`], resolve)
    }
    if (item.children) { //.length
      getAsyncRoutes(item.children,str);
    }
    str.push(route)
  });
  return str
};

export const setAsyncRoutes = menu => {
  const _menu = getAsyncRoutes(menu);

  let menu_total = [{
    path: '/',
    redirect: '/commons/user/index',
    component: (resolve) => require(['@/views/HomeView'], resolve)
  }, {
    path: '/vip',
    component: (resolve) => require(['@/views/vipView'], resolve),
    children: [
      {
        path: '/vip/vip-pay',
        name: '会员购买',
        component: (resolve) => require(['@/views/commons/user/new-vip'], resolve)
      },
      {
        path: '/vip/coupons',
        name: '我的卡券',
        component: (resolve) => require(['@/views/commons/user/coupons.vue'], resolve)
      }
    ]
  }, {
    path: '*',
    name: '404',
    component: (resolve) => require(['@/views/404'], resolve)
  }]

  menu_total[0].children = _menu

  // 将处理好的动态配置的路由通过 vue 提供的方式添加到 router 中，注意这个 _menu 的格式是和配置路由时的键 routes 一样格式的数组
  router.addRoutes(menu_total);

  // 路由 options 并不会随着 addRoutes 动态响应，所以要在这里进行设置
  router.options.routes = constantRoutes.concat(menu_total);
};
