<template>
  <div>
    <page-top-title name="支付成功"></page-top-title>
    <div class="imgBox">
      <img src="@/assets/images/order/success.svg" alt="">
    </div>
    <div class="stx">支付成功！</div>
    <div class="grtx"><span style="color:#409EFF">{{ time }}s将跳转页面！</span></div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      time: 3,
    }
  },
  components: {

  },
  created () {

  },
  mounted () {
    //订单来源(0商城1询比价2联合采购3招标4充值)
    let orderSource = this.$store.state.params.orderSource
    let params = JSON.parse(window.localStorage.getItem('params'))
    let enumStr = {
      '1': {
        '0': '/Purchaser/myPro/index',
        '1': '/Purchaser/myInquiryOrder/index',
        '2': '/Purchaser/myBiddingOrder/index',
        '3': '/Purchaser/myBiddingOrder/index'
      },
      '2': {
        '0': '/Purchaser/myPro/index',
        '1': '/supplier/myInquiryOrder/index',
        '2': '/supplier/myBidOrder/index',
        '3': '/supplier/myBidOrder/index'
      }
    }
    debugger
    if (orderSource == undefined) {
      this.$store.commit("RESET_BREAD")
      this.$router.push('/Purchaser/hybrid-order/index')
      // this.$router.push('/commons/user/index')
      return
    } 
    let timeInterval = setInterval(() => {
      this.time -= 1
      if (this.time == 0) {
        this.time = 3
        this.$store.commit("RESET_BREAD") 
        if (this.$store.state.params.orderSource == 7||this.$store.state.params.orderSource == 8) {
          let userInfo = JSON.parse(window.localStorage.getItem("user-info"));
          this.$router.push({
            path: `/commons/sign/sign`, query: {
              'ihl': 2,//隐藏导航栏
              'dataSource': 1,//来源
              'account': userInfo.account,//账号
               'activeName':this.$store.state.params.activeName,
              'signtab':this.$store.state.params.orderSource == 7?6:1
            }
          })
        } else {
          this.$router.push('/Purchaser/hybrid-order/index')
        }
        clearInterval(timeInterval)
      }
    }, 1000);
  },
  methods: {

  },
}

</script>
<style scoped lang='scss'>
.imgBox {
  display: flex;
  justify-content: center;

  img {
    text-align: center;
    height: 300px;
    width: 300px;
  }
}


.stx {
  text-align: center;
  color: #000;
  font-size: 28px;
  margin: 10px;
}

.grtx {
  text-align: center;
  color: rgb(85, 83, 83);
  font-size: 19px;
  margin: 10px;
}
</style>