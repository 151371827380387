// 我的招标
import request from '@/utils/request'
// 上传凭证
export function uploadCertificate (data) {
  return request({
    url: '/api/rs-order-service/ecomm/orderInfo/uploadCertificate',
    method: 'post',
    data: data
  })
}
// 详情
export function getInfo (id) {
  return request({
    url: `/api/rs-share-service/ecomm/tenderDemandInfo/detail`,
    method: 'get',
    params: {
      id: id
    }
  })
}
//查看所有
export function listAll (data) {
  return request({
    url: '/api/rs-share-service/ecomm/tenderDemandInfo/adminList',
    method: 'get',
    params: data
  })
}
//查看自己
export function listSelf (data) {
  return request({
    url: '/api/rs-share-service/ecomm/tenderDemandInfo/list',
    method: 'get',
    params: data
  })
}
//新增
export function addOne (data) {
  return request({
    url: '/api/rs-share-service/ecomm/tenderDemandInfo/save',
    method: 'post',
    data: data
  })
}

//修改
export function updOne (data) {
  return request({
    url: '/api/rs-share-service/ecomm/tenderDemandInfo/update',
    method: 'post',
    data: data
  })
}

//送审
export function sendCheck (data) {
  return request({
    url: '/api/rs-share-service/ecomm/tenderDemandInfo/submitReview',
    method: 'get',
    params: data
  })
}

//撤销
export function revoke (data) {
  return request({
    url: '/api/rs-share-service/ecomm/tenderDemandInfo/revoke',
    method: 'get',
    params: data
  })
}

//关闭
export function close (data) {
  return request({
    url: '/api/rs-share-service/ecomm/tenderDemandInfo/close',
    method: 'get',
    params: data
  })
}

//删除
export function remove (data) {
  return request({
    url: `/api/rs-share-service/ecomm/tenderDemandInfo/remove?ids=${data.ids}`,
    method: 'post',
    data: data
  })
}

//保证金列表
export function payList (data) {
  return request({
    url: '/api/rs-share-service/ecomm/tenderDemandInfo/payList',
    method: 'get',
    params: data
  })
}

//保证金缴纳
export function surepay (data) {
  return request({
    url: '/api/rs-share-service/ecomm/tenderDemandInfo/payBond',
    method: 'post',
    data: data
  })
}

//澄清文件列表
export function clarifyfileList (data) {
  return request({
    url: '/api/rs-share-service/ecomm/tenderDemandInfo/clarifyfileList',
    method: 'get',
    params: data
  })
}


//发布澄清文件
export function releaseClarifyfile (data) {
  return request({
    url: '/api/rs-share-service/ecomm/tenderDemandInfo/releaseClarifyfile',
    method: 'post',
    data: data
  })
}

//删除澄清文件
export function removeClarifyfileById (data) {
  return request({
    url: `/api/rs-share-service/ecomm/tenderDemandInfo/removeClarifyfileById?ids=${data.ids}`,
    method: 'post',
    data: data
  })
}

//开标
export function openBid (data) {
  return request({
    url: '/api/rs-share-service/ecomm/tenderDemandInfo/openBid',
    method: 'get',
    params: data
  })
}

//开标人列表
export function bidOpenerList (data) {
  return request({
    url: '/api/rs-tender-service/ecomm/sc/tenderDemandInfo/bidOpenerList',
    // url: '/api/rs-share-service/ecomm/tenderDemandInfo/bidOpenerList',
    method: 'get',
    params: data
  })
}


//导入模板
export function uploadTemplate (data) {
  return request({
    url: '/api/rs-share-service/ecomm/tenderDemandInfo/uploadTemplate',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: data
  })
}

//下载模板
export function downloadTemplate (data) {
  return request({
    url: '/api/rs-share-service/ecomm/tenderDemandInfo/downloadTemplate',
    method: 'get',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    params: data,
    responseType: 'blob'
  })
}
//选标
export function selectBid (data) {
  return request({
    url: '/api/rs-share-service/ecomm/tenderDemandInfo/selectBid',
    method: 'get',
    params: data
  })
}
//供应商下拉列表
export function selectSupplierList (data) {
  return request({
    url: '/api/rs-share-service/ecomm/tenderDemandInfo/selectSupplierList',
    method: 'get',
    params: data
  })
}

//招标商品信息
export function bidCommodityList (data) {
  return request({
    url: '/api/rs-share-service/ecomm/tenderDemandInfo/bidCommodityList',
    method: 'get',
    params: data
  })
}

//招标商品确认下单
export function submitOrder (data) {
  return request({
    url: '/api/rs-order-service/ecomm/orderInfo/submitOrder',
    method: 'post',
    data: data
  })
}
//招标商品确认支付
export function pay (data) {
  return request({
    url: '/api/rs-fund-service/ecomm/pay/orderPay',
    method: 'post',
    data: data
  })
}


/**
 * 获取支付状态
 * @param {"orderInfoDTOList":[{"orderCode":"订单号"},{"orderCode":"订单号"}]}  
 * @returns 
 */
export function getPayStatus (data) {
  return request({
    url: '/api/rs-order-service/ecomm/orderInfo/getPayStatus',
    method: 'post',
    data: data
  })
}

//选择地址
export function choseAddress (data) {
  return request({
    url: "/api/rs-product-service/ecomm/basket/basketProductFreight",
    method: "post",
    data: data
  })
}

//获取对公转账list
export function getDGZZList () {
  return request({
    url: '/api/rs-cms-service/ecomm/dict-biz/list?code=tran_bank_info',
    method: "get"
  })
}
//公对公转账
export function otherPayB2B (data) {
  return request({
    url: '/api/rs-fund-service/ecomm/pay/otherPayB2B',
    method: "post",
    data:data
  })
}
//公对公转账
export function getPayStatusAll (data) {
  return request({
    url: '/api/rs-order-service/ecomm/orderInfo/getPayStatusAll',
    method: "post",
    data:data
  })
} 

//获取折扣
export function getDiscountNum () {
  return request({
    url: "/api/rs-product-service/ecomm/productInfo/getDiscountNum",
    method: "get"
  })
}

//计算订单数据价格
export function calOrderData (data) {
  return request({
    url: "/api/rs-order-service/ecomm/orderInfo/create-order-data",
    method: "post",
    data:data
  })
}
//获取此消费券可优惠额度
export function calculateDiscount (data) {
  return request({
    url: "/api/rs-membership-service/voucher/calculate-discount",
    method: "post",
    data:data
  })
}
